import styled from "styled-components";

export const DivStyle = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  overflow: ${props => props.overflow};
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */

  &.header-border{
    border: 1px solid ${props => props.theme.color.white};
    padding: 1.25em 4em;
  }

  &.withborder-bottom{
    border-bottom: 1px solid ${props => props.theme.color.secondary};
  }

  &.line-vertical{
    background: ${props => props.theme.color.secondary};
    width: 1px;
    height: 100%;
    margin: 0 1em;
  }

  &.responsive-navbar{
      display:none;
  }
  &.video-bg{
    position:absolute;
    height:calc(100vh - 69px);
    width:100%;
    overflow:hidden;
    z-index:-1;
   
  }

  @media only screen and (max-width: 768px) {
    &.responsive-fullwidth{
      width:100%;
    }
    &.responsive-fullheight{
      height:100%;
    }
    &.responsive-navbar{
      display:flex;
      height:100%;
    }
    &.responsive-margin-none{
      margin: 0 0;
    }
  }

  

  /* &.number-circle{
    display: flex;
    justify-content:center;
    align-items: center;
    background: ${props => props.theme.color.secondary};
    width:1em;
    height:1em;
    border-radius: 15px;
    margin: 0.3em 1em 0 -1em
  } */
`;
