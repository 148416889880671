import React from 'react'
import {Section,Flex,Text} from '../../components'
import {Container} from 'reactstrap'
import Bg from '../../assets/bg-product-2.png'
import home0 from '../../assets/home0.png'
import home1 from '../../assets/home1.png'
import home2 from '../../assets/home2.png'
import home3 from '../../assets/home3.png'
import home4 from '../../assets/home4.png'
import home5 from '../../assets/home5.png'
import home6 from '../../assets/home6.png'

const ElectronicAndHome = () => {
return (
    <Section bgImage={Bg} padding="4em 0">
        <Container>
            <Flex flexDirection="column" justifyContent="center" alignItems="center">
                <Text.H3 aos="fade-up"
            duration="1200"
                fontFamily="'Lora', serif"
                fontColor="white" weight={600} width="100%" textAlign="center" spacing="3px" margin='0 0 2em 0'>
                    <span
                style={{
                    color: "#E7A53C",
                    fontWeight: "600",
                    margin: "0 0.25em",
                    shadow: "0",
                }}
                > Electriacal and Home appliances </span>
                    Parts
                </Text.H3>
                <Flex width="100%" justifyContent="center" alignItems="center" wrap="wrap">
                    <img style={{width:'256px', height:'256px'}} src={home0} alt="home0"/>
                    <Flex aos="fade-right" duration="1200"flexDirection="column"  wrap="wrap"  height="100%">
                        <Flex justifyContent="space-around" wrap="wrap" width="100%">
                            <Flex flexDirection="column" alignItems="center" margin="1em 2em">
                                <Flex height="128px" alignItems="center">
                                    <img src={home1} alt="home1" style={{width: '128px', marginBottom:'1em'}}/>
                                </Flex>
                                <Text.H5 fontFamily="'Lora', serif" fontColor="white" weight={800}>Electric floor plates</Text.H5>
                            </Flex>
                            <Flex flexDirection="column" alignItems="center" margin="1em 2em">
                                <Flex height="128px" alignItems="center">
                                    <img src={home2} alt="home2" style={{width: '128px', marginBottom:'1em'}}/>
                                </Flex>
                                <Text.H5 fontFamily="'Lora', serif" fontColor="white" weight={800}>Telephone keypads</Text.H5>
                            </Flex>
                            <Flex flexDirection="column" alignItems="center" margin="1em 2em">
                                <Flex height="128px" alignItems="center">
                                    <img src={home3} alt="home3" style={{width: '128px', marginBottom:'1em'}}/>
                                </Flex>
                                <Text.H5 fontFamily="'Lora', serif" fontColor="white" weight={800}>Intercom parts</Text.H5>
                            </Flex>
                        </Flex>
                        <Flex justifyContent="space-around" width="100%" wrap="wrap">
                            <Flex flexDirection="column" alignItems="center" margin="1em 2em">
                            <Flex height="128px" alignItems="center">
                                <img src={home4} alt="home4" style={{width: '128px', marginBottom:'1em'}}/>
                                </Flex>
                                <Text.H5 fontFamily="'Lora', serif" textAlign="center" fontColor="white" width="128px" lineHeight="1.5em" weight={800}>Meat grinder components</Text.H5>
                            </Flex>
                            <Flex flexDirection="column" alignItems="center" margin="1em 2em">
                            <Flex height="128px" alignItems="center">
                                <img src={home5} alt="home5" style={{width: '128px', marginBottom:'1em'}}/>
                                </Flex>
                                <Text.H5 fontFamily="'Lora', serif" fontColor="white" weight={800}>Cooking pots</Text.H5>
                            </Flex>
                            <Flex flexDirection="column" alignItems="center" margin="1em 2em">
                            <Flex height="128px" alignItems="center">
                                <img src={home6} alt="home6" style={{width: '128px', marginBottom:'1em'}}/>
                                </Flex>
                                <Text.H5 fontFamily="'Lora', serif"textAlign="center"  width="128px" fontColor="white" weight={800}>Fishing spinder components</Text.H5>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Container>
    </Section>
)
}
export default ElectronicAndHome