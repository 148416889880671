import React from "react";
import VideoCover from "react-video-cover";
import { Container } from "reactstrap";
import { Section, Text, Flex, Button, DivWrapper } from "../../components";
import Bg from "../../assets/video-Bg-Home.mp4";
import { Link } from "react-scroll";
const LandingPage = () => {
  return (
    <Section>
      <DivWrapper className="video-bg">
        <VideoCover
          videoOptions={{
            src: Bg,
            autoPlay: "autoplay",
            muted: "muted",
            loop: "loop",
            playsInline: "playsinline",
          }}
          style={{
            filter: "brightness(.5)",
          }}
        />
      </DivWrapper>
      <Container>
        <Flex
          height="calc(100vh - 69px)"
          justifyContent="center"
          flexDirection="column"
        >
          <Text.H1
            aos="fade-right"
            duration="1000"
            weight={600}
            fontColor="white"
          >
            You can draw
          </Text.H1>
          <Text.H1
            aos="fade-right"
            duration="1400"
            weight={600}
            fontColor="white"
          >
            We can do
          </Text.H1>
          <DivWrapper margin="1em 0 0 0 ">
            <Link smooth={true} to="intro">
              <Button
                aos="fade-right"
                duration="1600"
                className="outline px-5"
                padding="2em"
              >
                Learn more
              </Button>
            </Link>
          </DivWrapper>
        </Flex>
      </Container>
    </Section>
  );
};

export default LandingPage;
