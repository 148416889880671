import React from 'react'
import {Section,Flex,Text,Button,DivWrapper} from '../../components'
import {Container} from 'reactstrap'
import Team1 from '../../assets/team1.png'
import Team2 from '../../assets/team2.png'
import Team3 from '../../assets/team3.png'
import { useHistory } from "react-router-dom";

const OurTeam = () => {
    const history = useHistory();
return (
<Section bgcolor="primary" padding="2em 0">
    <Container>
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
            <Text.H3 
             aos="fade-left"
             duration="1200"
            fontFamily="'Lora', serif"
            margin="1em 0" fontColor="white"  spacing="3px">
                OUR  <span
              style={{
                color: "#E7A53C",
                margin: "0 0.25em",
                shadow: "0",
              }}
            > TEAM </span>
            </Text.H3>
            <Text.H5  aos="fade-right"
            duration="1200" className="responsive-fullwidth"width="700px" spacing="1px" lineHeight="2.5em" textAlign="center" fontColor="white">
            “We provide optimally gated die cast mold designs using our unique die casting systems and produce high quality, low cost finished and assembled products for you and your company.”
            </Text.H5>
          <TeamComponent/>
            <Button aos="fade-up"
            duration="1600"onClick={() => history.push("/contact")}margin="2em 0 3em 0" className="inline px-4" >Contacct Us</Button>
        </Flex>
    </Container>
</Section>
)
}


const TeamComponent = () => {
    return(
        <Flex justifyContent="center" width="100%" wrap="wrap" margin="2em 0 0 0">
        <Flex flexDirection="column" width="300px" alignItems="center" margin="1.5em">
            <DivWrapper  aos="fade-up"
            duration="1200">
                <img style={{width:'250px', height:'250px'}} src={Team2} alt="team1"/>
            </DivWrapper>
            <Text.Custom aos="fade-up"
            duration="1400"size="default"fontColor="white" margin="1em 0" spacing="1px">Mr. Somsak Ruaysawangboon</Text.Custom>
            <Text.Custom aos="fade-up"
            duration="1600"size="xSmall"fontColor="white" weight={700} spacing="1px">President of MIRCRO PRECESION CO.,LTD</Text.Custom>
        </Flex>
        <Flex flexDirection="column" width="300px" alignItems="center" margin="1.5em">
            <DivWrapper aos="fade-up"
            duration="1200">
                <img style={{width:'250px', height:'250px'}} src={Team1} alt="team1"/>
            </DivWrapper>
            <Text.Custom aos="fade-up"
            duration="1400"size="default"fontColor="white" margin="1em 0" spacing="1px">Mr. Warrut Ruaysawangboon</Text.Custom>
            <Text.Custom aos="fade-up"
            duration="1600"size="xSmall"fontColor="white" weight={700} spacing="1px">Managing Director</Text.Custom>
        </Flex>
        <Flex flexDirection="column" width="300px" alignItems="center" margin="1.5em">
            <DivWrapper aos="fade-up"
            duration="1200">
                <img style={{width:'250px', height:'250px'}} src={Team3} alt="team1"/>
            </DivWrapper>
            <Text.Custom aos="fade-up"
            duration="1400"size="default" fontColor="white" margin="1em 0" spacing="1px">Mr. Hajime Takano</Text.Custom>
            <Text.Custom aos="fade-up"
            duration="1600"size="xSmall" fontColor="white" weight={700} spacing="1px">General Manager</Text.Custom>
        </Flex>
        </Flex>

    )
}
export default OurTeam