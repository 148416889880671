import React from 'react'
import {Section,Flex,Text,ImgWrapper} from '../../components'
import {Container} from 'reactstrap'  
import Medical1 from '../../assets/medical1.png'
import Medical2 from '../../assets/medical2.png'
import Bg from '../../assets/bg-medical.png'
const Medical = () => {
return (
    <Section bgImage={Bg} padding="4em 0">
        <Container>
        <Text.H3 aos="fade-up"
            duration="1200"
                fontFamily="'Lora', serif"
                fontColor="white" weight={600} textAlign="center" spacing="3px" margin='0 0 1em 0'>
                    <span
                style={{
                    color: "#E7A53C",
                    fontWeight: "600",
                    margin: "0 0.25em",
                    shadow: "0",
                }}
                > Medical </span>
                    Device
                </Text.H3>
                <Flex width="100%" height="100%"  alignItems="flex-end" justifyContent="center" wrap="wrap">
                    <Flex aos="fade-up"
            duration="1200"margin="1em 0 0 0" flexDirection="column" justifyContent="center" alignItems="center" width="500px">
                        <ImgWrapper source={Medical1} alt="med1" width="250px"/>
                        <Text.H5 fontFamily="'Lora', serif" fontColor="white" margin="1em 0"weight={800}>Infusion Pump</Text.H5>
                    </Flex>
                    <Flex aos="fade-up"
            duration="1200"margin="1em 0 0 0"flexDirection="column" justifyContent="center" alignItems="center" width="500px">
                        <ImgWrapper source={Medical2} alt="med1" width="250px" style={{marginBottom:'2em'}}/>
                        <Text.H5 fontFamily="'Lora', serif" fontColor="white" margin="1em 0" weight={800}>Infusion pump</Text.H5>
                    </Flex>
                </Flex>
        </Container>
    </Section>
)
}
export default Medical