import React from 'react'
import {Section,Flex,Text, DivWrapper} from '../../components'
import {Container,Card,CardBody} from 'reactstrap'
import Allies1 from '../../assets/allies1.png'
import Allies2 from '../../assets/allies2.png'
const Alliances = () => {
return (
    <Section bgcolor="white" padding="2em 0">
        <Container>
        <Text.H3 
        aos="fade-up"
        duration="1200"
        fontFamily="'Lora', serif"
        fontColor="grey" width="100%" textAlign="center" spacing="1px" margin='1em 0'>
                OUR
                 <span
              style={{
                color: "#E7A53C",
                margin: "0 0.25em",
                shadow: "0",
              }}
            >ALLIANCES</span>
            <Flex width="100%"  justifyContent="space-around" wrap="wrap" margin="1em 0">
                <DivWrapper aos="fade-right" duration="1200">
                    <Card style={{margin: '1em 0'}}>
                  <CardBody>
                     <Flex className="allies-card"alignItems="center" width="450px" height="160px">
                        <img src={Allies1} alt="al1" style={{width:'125px', margin:'0 0.5em'}}/>
                        <Flex flexDirection="column" justifyContent="space-between">
                            <Text.H6 fontColor="secondary" margin="0 0 1em 0">Elation CO.LTD.</Text.H6>
                            <Text.Custom className="full-width"size="xxSmall" width="250px">Plastic Injection</Text.Custom>
                        </Flex>
                     </Flex>  
                  </CardBody>
              </Card>
                </DivWrapper>
              <DivWrapper aos="fade-left" duration="1200">
                   <Card style={{margin: '1em 0'}}>
                  <CardBody>
                     <Flex className="allies-card"alignItems="center" width="450px" height="160px">
                        <img src={Allies2} alt="al1" style={{width:'125px', margin:'0 0.5em'}}/>
                        <Flex flexDirection="column" justifyContent="space-between">
                            <Text.H6 fontColor="secondary" margin="0 0 1em 0">BS Casting 
                                Aluminum </Text.H6>
                            <Text.Custom className="full-width"size="xxSmall" width="250px">Gravity and 
                            Sand Casting</Text.Custom>
                        </Flex>
                     </Flex>  
                  </CardBody>
              </Card>
              </DivWrapper>
            </Flex>   
            </Text.H3>
        </Container>
    </Section>

)
}
export default Alliances