import React from 'react'
import{BadgeStyle } from './style'

export const Badge = props => {
    const {children, className} = props
    return(
        <BadgeStyle className={className}>
            {children}
        </BadgeStyle>
    )
}