import React from 'react'
import {Section,Flex,Text} from '../../components'
import {Container} from 'reactstrap'
import Cer1 from '../../assets/cer1.png'
import Cer2 from '../../assets/cer2.png'
const QualityStandard = () => {
return (
    <Section bgcolor="white" padding="2em 0 0 0">
        <Container>
        <Text.H3 aos="fade-up"
        duration="1200"
           fontFamily="'Lora', serif"
        fontColor="grey" width="100%" textAlign="center" spacing="1px" margin='1em 0'>
                OUR
                 <span
              style={{
                color: "#E7A53C",
                margin: "0 0.25em",
                shadow: "0",
              }}
            >QUALITY</span>
               <span style={{color:'#09377e'}}>STANDARDS</span>
            </Text.H3>
              <Flex width="100%"  justifyContent="space-around" wrap="wrap">
                  <img data-aos="fade-right" data-aos-duration="1200"src={Cer1} alt="cer1" style={{height:'500px', margin:'2em 0'}}/>
                  <img data-aos="fade-left"data-aos-duration="1200"src={Cer2} alt="cer2" style={{height:'500px', margin:'2em 0'}}/>
              </Flex>
        </Container>
    </Section>
)
}
export default QualityStandard