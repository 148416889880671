import React from "react";
import { Section, Flex, DivWrapper, Text } from "../../components";
import IntroPic from "../../assets/intro.png";
import CountUp from "react-countup";

const Intro = () => {
  return (
    <Section id="intro">
      <Flex className="responsive-fullwidth-column">
        <DivWrapper aos="fade-right"
            duration="1000" className="responsive-fullwidth responsive-fullheight" width="50%"  overflow="hidden">
          <img src={IntroPic} style={{width:'100%',height: '100%'}} alt="intro" />
        </DivWrapper>
        <Flex
          className="responsive-fullwidth-column"
          justifyContent="center"
          alignItems="center"
          width="50%"
          flexDirection="column"
          margin="1em 0"
        >
          <Text.H3
            fontFamily="'Lora', serif"
            margin="0 0 1em 0"
            spacing="3px"
            fontColor="grey"
          >
          FOR OVER
            <span
              style={{
                color: "#E7A53C",
                fontWeight: "400",
                fontSize: "1.5em",
                margin: "0 0.25em",
                shadow: "0",
              }}
            >
              <CountUp delay={2}start={0} end={30} duration={10} />
            </span>
            <span style={{ color: "#3A5277", fontWeight: "600" }}>YEARS</span>
          </Text.H3>
          <Text.H5
           aos="fade-left"
           duration="1200"
            margin="0 2em"
            spacing="1px"
            lineHeight="2.5em"
            textAlign="center"
          >
            Micro has been dedicated to providing companies with superior mold
            making, die casting, and tooling, along with metal finishing and CNC
            machining through state of the art machinery and processes.
            Specializing in aluminum and zinc alloys, engineers and production
            staff will develop a high pressure die casting process that will
            produce your parts economically and with reliable quality.
          </Text.H5>
        </Flex>
      </Flex>
    </Section>
  );
};
export default Intro;
