import React from 'react'
import {Section,InteractiveProduct,Text} from '../../components'
import {Container} from 'reactstrap'
const Automotive = () => {
return (
    <Section padding="4em 0">
        <Container>
        <Text.H3 aos="fade-up"
            duration="1200" weight={600} fontFamily="'Lora', serif" margin="0 0 2em 0" fontColor="grey" width="100%" textAlign="center"  spacing="3px">   
              Automotive
                <span style={{color: "#E7A53C",fontWeight: "600",margin: "0 0.25em",shadow: "0",}}> 
                Parts 
                </span>
            </Text.H3> 
            <InteractiveProduct/>
        </Container>
    </Section>
)
}
export default Automotive