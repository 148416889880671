import React from 'react'
import {Section,Flex,Text,ImgWrapper,Badge, DivWrapper} from '../../components'
import {Container,} from 'reactstrap'
import P1 from '../../assets/partner1.png'
import P2 from '../../assets/partner2.png'
import P3 from '../../assets/partner3.png'
import P4 from '../../assets/partner4.png'
import P5 from '../../assets/partner5.png'
import P6 from '../../assets/partner6.png'
import P7 from '../../assets/partner7.png'
import P8 from '../../assets/partner8.png'
import { useHistory } from "react-router-dom";
const OurPartners = () => {
    const history = useHistory();
return (
    <Section bgColor="white" padding="2em 0">
        <Container>
        <Flex alignItems="center" justifyContent="center" width="100%">
            <Text.H3 aos="fade-up"
            duration="1200"className="mobile-1em" fontFamily="'Lora', serif" margin="1em 0" fontColor="grey"   spacing="3px"> OUR  
                <span style={{color: "#E7A53C",fontWeight: "600",margin: "0 0.25em",shadow: "0",}}>
                VALUABLE 
                </span>
                <span style={{color: "#3A5277",fontWeight: "600",margin: "0 0.25em",shadow: "0",}}> 
                PARTNERS 
                </span>
            </Text.H3> 
            <Text.H6 >
                <DivWrapper aos="fade-up"
            duration="1200" onClick={() => history.push("/contact")} >
                <Badge color="secondary" className="py-1 px-2">
                    View all
                </Badge>
                </DivWrapper>
            </Text.H6>
        </Flex>
        <Flex  margin="2em 0 1em 0" alignItems="center" justifyContent="space-between" wrap="wrap">
        <Flex aos="fade-up"
            duration="1200"className="responsive-partner" justifyContent="center" width="277.5px"><ImgWrapper source={P1} className="partner"/></Flex>
        <Flex aos="fade-up"
            duration="1300"className="responsive-partner"justifyContent="center"width="277.5px"><ImgWrapper source={P2} className="partner"/></Flex>
        <Flex aos="fade-up"
            duration="1400"className="responsive-partner"justifyContent="center"width="277.5px"><ImgWrapper source={P3} className="partner"/></Flex>
        <Flex aos="fade-up"
            duration="1500"className="responsive-partner"justifyContent="center"width="277.5px"><ImgWrapper source={P4} className="partner"/></Flex>
        <Flex aos="fade-up"
            duration="1600"className="responsive-partner"justifyContent="center"width="277.5px"><ImgWrapper source={P5} className="partner"/></Flex>
        <Flex aos="fade-up"
            duration="1700"className="responsive-partner"justifyContent="center"width="277.5px"><ImgWrapper source={P6} className="partner"/></Flex>
        <Flex aos="fade-up"
            duration="1800"className="responsive-partner"justifyContent="center"width="277.5px"><ImgWrapper source={P7} className="partner"/></Flex>
        <Flex aos="fade-up"
            duration="1900"className="responsive-partner"justifyContent="center"width="277.5px"><ImgWrapper source={P8} className="partner"/></Flex>
        </Flex>
        </Container>
    </Section>
)
}
export default OurPartners