import React from 'react'
import {Section,Flex,DivWrapper,Text} from '../../components'
import Bg from '../../assets/bg-news-header.png'

const Header = () => {
return (
    <Section bgImage={Bg}>
        <Flex width="100%" height="400px" alignItems="center" justifyContent="center">
            <DivWrapper className="header-border">
            <Text.H3 
            fontFamily="'Lora', serif"
            margin="0 2em" fontColor="white" weight={600}  spacing="3px">
              NEW<span
              style={{
                color: "#E7A53C",
                fontWeight: "600",
                margin: "0",
                shadow: "0",
              }}
            >S</span>
            </Text.H3>
            </DivWrapper>
        </Flex>
    </Section>
)
}
export default Header