import styled from 'styled-components'

export const NewsWrapper = styled.div`
  width: 200px;
  height:300px;
  border-radius: 15px;
  position:relative;
  padding:5px;
  margin: 3em 0 0 0;
   
    &:before{
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-image: url(${props => props.bgImage});
 
  background-position: center;
  filter: brightness(0.5);
  z-index:-1;
  border-radius: 15px;
    }
`

export const Header = styled.h6`
  font-weight:700;
  color:white;
  margin-top:7em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

`



export const Text = styled.h6`
  color:white;
  font-size:0.6em;
  height:56px;
  line-height:15px;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;

`