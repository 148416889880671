import React from 'react'
import {Section,Flex,Text,Button} from '../../components'
import {Container} from 'reactstrap'
import { useHistory } from "react-router-dom";

const WhyUs = () => {
  const history = useHistory();
return (
<Section bgcolor="primary" padding="2em 0 0 0">
    <Container>
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
            <Text.H3   aos="fade-up"
            duration="1200"
            fontFamily="'Lora', serif"
            margin="1em 0" fontColor="white" spacing="3px">
            WHY  <span
              style={{
                color: "#E7A53C",
                margin: "0 0.25em",
                shadow: "0",
              }}
            > US </span>
            </Text.H3>
            <Text.H5  aos="fade-up"
            duration="1200" className="responsive-fullwidth" width="700px" spacing="1px" lineHeight="2.5em" textAlign="center" fontColor="white">
           Micro Precision is able to deliver global tooling programs for new preform molds or conversions of existing tooling to latest generation technology or cavitation. Preform molds are produced at dedicated state-of-the-art facilities and we are continually investing to increase capacity, reduce lead times and maximize part quality.
            </Text.H5>
            <Button  aos="fade-up"
            duration="1200" onClick={() => history.push("/experience")}margin="2em 0" className="inline px-4">Find Our More</Button>
        </Flex>
    </Container>
</Section>
)
}
export default WhyUs