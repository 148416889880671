import React, { useEffect } from "react";
import { Section, Flex, NewsBadge, Text, DivWrapper } from "../../components";
import { Container } from "reactstrap";

const AllNews = (props) => {
  const { news } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Section bgColor="white" padding="0 0 4em 0">
      <Container>
        <Text.H3
          fontFamily="'Lora', serif"
          margin="2em 0 0 0"
          fontColor="grey"
          spacing="3px"
        >
          RECENTLY
          <span
            style={{
              color: "#E7A53C",
              fontWeight: "600",
              margin: "0 0.25em",
              shadow: "0",
            }}
          >
            NEWS
          </span>
        </Text.H3>
        <Flex justifyContent="space-around" wrap="wrap">
          {news &&
            news.reverse().map((data, index) => (
              <DivWrapper margin="0 1em">
                <NewsBadge
                  key={data.no}
                  head={data.header}
                  info={data.info}
                  pic={data.pic}
                />
              </DivWrapper>
            ))}
        </Flex>
      </Container>
    </Section>
  );
};
export default AllNews;
