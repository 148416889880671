import React,{useEffect} from 'react'
import { Section,Flex,Text } from '../../components'
import {Container} from 'reactstrap'
import { useLocation } from "react-router-dom";
import LatestNews from '../home/latestNews'
const NewsDetail = (props) => {
    const{news} = props
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0)
      })
return (
    <Section padding='4em 0'>
        <Container>
            <Text.H2 fontColor="grey" aos="fade-up" duration="1000">{location.state.head}</Text.H2>
            <Flex aos="fade-up" duration="1200" margin="2em 0" width="100%" justifyContent="center">
                <img src={location.state.pic[0]} alt="pic0" style={{width:'100%',height:'100%'}}/>
            </Flex>
           <Text.H4 aos="fade-up" duration="1200"width="100%" textAlign="center">{location.state.info}</Text.H4>
                <Text.H3 fontFamily="'Lora', serif" weight={400} margin="4em 0 2em 0">RELATED <span style={{color: "#E7A53C",fontWeight: "600",margin: "0 0.25em",shadow: "0",}}> PICTURES </span></Text.H3>
                <Flex flexDirection="column" alignItems="center" >
                {location.state.pic && location.state.pic.map((data,index) =>
                    <img data-aos="fade-up" data-aos-duration="1200" src={data} alt={index} key={index} style={{width:'350px',margin:'2em 0'}}/>
                )}
           </Flex>
          <LatestNews news={news}/>
        </Container>
    </Section>
)
}
export default NewsDetail