const firebase = require("firebase");
export const firebaseConfig = {
  apiKey: "AIzaSyAxgdH68pcsTBlpZsZXqmiAq-1AqbFdkfU",
  authDomain: "microprecision-webapp.firebaseapp.com",
  databaseURL: "https://microprecision-webapp.firebaseio.com",
  projectId: "microprecision-webapp",
  storageBucket: "microprecision-webapp.appspot.com",
  messagingSenderId: "848802190528",
  appId: "1:848802190528:web:e8fd7013dbabaa8a1b4925"
};

const Firebase = firebase.initializeApp(firebaseConfig);
export default Firebase;
