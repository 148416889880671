import styled from 'styled-components'
export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 80;
  top: 0;
  right: 0;
  transform: ${props =>
    props.collapsed ? 'translateX(100%)' : 'translateX(0)'};
`

export const SlideDrawerStyled = styled.div`
  height: 100%;
   background: #1b1e21;
   position: fixed;
   display:flex;
   flex-direction:column;
   justify-content:center;
   top: 0;
   right: 0;
   width: 70%;
   z-index: 90;
   box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
   transform: ${props =>
     props.collapsed ? 'translateX(100%)' : 'translateX(0)'};
  transition: transform 0.5s ease-out;
`


export const DivStyle = styled.div`
  /* position: absolute;
  top: 25px;
  right: 75px; */
  display:flex;
  flex-direction:column;
  justify-content:center;
  height: 70px;
  width: 33px;
  z-index: 99 !important;
  cursor: pointer;
`

export const SpanStyle = styled.span`
  display: block;
  
  height: 4px;
  cursor: pointer;
  margin-bottom: 5px;
  position: relative;
  z-index: 99;
  border-radius: 3px;
  transform-origin: 0px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  background: 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  opacity: 0.55s ease;
  &.one {
    width: 33px;
    transform: ${props => props.collapsed ? 'none' : 'rotate(45deg) translate(-2px,0px)'};
    background: ${props => props.collapsed ? `${props.theme.color.white}` : `${props.theme.color.secondary}`};
    transform-origin: ${props => (props.collapsed ? '0% 0%' : 'none')};
  }
  &.two {
    width: 15px;
    transform-origin: ${props => (props.collapsed ? '0% 100%' : 'none')};
    opacity: ${props => (props.collapsed ? '1' : '0')};
    background: ${props =>
      props.collapsed ? `${props.theme.color.white}` : `${props.hoverColor}`};
    transform: ${props =>
      props.collapsed ? 'none' : 'rotate(0deg) scale(0.2, 0.2)'};
  }
  &.three {
    width: 25px;
    transform: ${props =>
      props.collapsed ? 'none' : 'rotate(-45deg) translate(-5px, 0)'};
    background: ${props =>
      props.collapsed ? `${props.theme.color.white}` : `${props.theme.color.secondary}`};
  }
`