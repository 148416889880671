import React,{useState,useEffect} from "react";
import Firebase from './configs/fbConfigs'
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Home, OurProduct,Experience,Contact,News,NewsDetail } from "./pages";
import {NavBar,Footer} from './components'

const App = () => {
  const[news,setNews] = useState([])
  let Firestore = Firebase.firestore()
 
useEffect(()=>{
   Firestore.collection("news").where("show","==",true).get().then(function(snapshot){
  snapshot.forEach(function(docs){
    setNews(prevState=> [...prevState, docs.data()]);
    })
  });

},[Firestore])
  return (
    <BrowserRouter>
    <NavBar/>
      <Switch>
        <Route exact path={`/`} render={() => <Home news={news} />} />
        <Route exact path={`/product`} render={() => <OurProduct news={news}/>} />
        <Route exact path={`/experience`} render={() => <Experience />} />
        <Route exact path={`/contact`} render={() => <Contact />} />
        <Route exact path={`/news`} render={() => <News news={news}/>} />
        <Route exact path={`/newsdetail`} render={() => <NewsDetail news={news}/>} />
        <Route  path={`*`} render={() => <Home news={news} />} />
      </Switch>
<Footer/>
    </BrowserRouter>
  );
};

export default App;
