import React from "react";
import { Section, DivWrapper, Flex, Text } from "../../components";
import Logo from "../../assets/logo.png";
import Map from "pigeon-maps";
import Overlay from "pigeon-overlay";

const MapComponents = () => {
  function mapTilerProvider(x, y, z, dpr) {
    return `https://c.tile.openstreetmap.org/${z}/${x}/${y}.png`; //<--- tile provider url, should provide colorful map from openstreet
  }
  return (
    <Section padding="0">
      <DivWrapper height="400px">
        <Map
          provider={mapTilerProvider}
          center={[13.573689, 100.774511]}
          zoom={12}
        >
          <Overlay anchor={[13.573689, 100.774511]} offset={[30, 40]}>
            <Flex alignItems="baseline" cursor="pointer">
              <DivWrapper width="50px" margin="0 0.5em 0 0">
                <img src={Logo} alt="logo" style={{width:'50px'}}/>
              </DivWrapper>
              <Flex flexDirection="column">
                <Flex>
              <Text.H5 weight={500}>MICRO</Text.H5>
              <Text.H5 weight={700}>PRECISION</Text.H5>
                </Flex>
              <Text.H6 weight={700}>669-669/3 Moo.15 Theparak Rd. Km.21, Bangsaothong, Samutprakarn 10570</Text.H6>
            </Flex>
            </Flex>
          </Overlay>
        </Map>
      </DivWrapper>
    </Section>
  );
};

export default MapComponents;
