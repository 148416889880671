import React,{useEffect}  from "react";
import Header from './header'
import AllNews from './allNews'
const News = (props) => {
  const{news} = props
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
   <React.Fragment>
     <Header/>
     <AllNews news={news}/>
   </React.Fragment>
  );
};

export default News;
