import React,{useEffect}  from 'react'
import Header from './header'
import OurMachine from './ourMachine'
import QualityStandard from './qualityStandard'
import Alliances from './alliances'
const Experience = () =>{
    useEffect(() => {
        window.scrollTo(0, 0)
      })
    return(
        <React.Fragment>
                <Header/>
                <OurMachine/>
                <QualityStandard/>
                <Alliances/>
        </React.Fragment>
    )
}

export default Experience