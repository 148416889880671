import styled, { css } from "styled-components";

const defaultStyle = css`
  width: ${(props) => props.width};
  text-align: ${(props) => props.textAlign};
  font-weight: ${(props) => props.weight};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  color: ${(props) => props.theme.color[props.fontColor]};
  cursor: ${(props) => props.cursor};
  text-decoration: ${(props) => props.decoration};
  font-family: ${(props) => props.fontFamily};
  line-height: ${(props) => props.lineHeight};
  text-shadow: ${(props) => props.shadow};
  letter-spacing: ${props => props.spacing};
  &.nav {
    margin: 0 2em 0 0;
    color: ${(props) => props.theme.color.blue};
    font-weight: 600;
    opacity: 25%;
    cursor: pointer;
    transition-duration: 0.3s;
  }

  &.withborder-bottom{
    border-bottom: 1px solid ${props => props.theme.color.secondary};
  }

  &:hover {
    &.nav {
      opacity: 100%;
      padding-bottom: 3px;
      border-bottom: 3px solid ${(props) => props.theme.color.secondary};
    }
  }
`;

export const TextH1Style = styled.h1`
  ${defaultStyle};
  font-size: ${(props) => props.theme.fontSize.xLarge};
  @media only screen and (max-width: 1200px) {
    font-size: 3em;
  }
`;

export const TextH2Style = styled.h2`
  ${defaultStyle};
  font-size: ${(props) => props.theme.fontSize.large};

  :hover{
     &.responsive-nav{
      color: ${props => props.theme.color.secondary};
      transition-duration: 2;
  }
  }
 

  @media only screen and (max-width: 1200px) {
    font-size: 2.5em;
  }
`;

export const TextH3Style = styled.h3`
  ${defaultStyle};
  font-size: ${(props) => props.theme.fontSize.medium};
  @media only screen and (max-width: 1200px) {
    font-size: 1.5em;
  }
  @media only screen and (max-width: 768px) {
    &.mobile-1em{
      font-size: 1em;
    }
  }
`;
export const TextH4Style = styled.h4`
  ${defaultStyle};
  font-size: ${(props) => props.theme.fontSize.small};
  @media only screen and (max-width: 1200px) {
    font-size: 1em;
  }
`;
export const TextH5Style = styled.h5`
  ${defaultStyle};
  font-size: ${(props) => props.theme.fontSize.default};
  @media only screen and (max-width: 1200px) {
    font-size: 0.7em;
  }
  @media only screen and (max-width: 768px) {
    &.responsive-fullwidth{
      width:100%;
    }
  }
`;
export const TextH6Style = styled.h6`
  ${defaultStyle};
  font-size: ${(props) => props.theme.fontSize.xSmall};
  @media only screen and (max-width: 1200px) {
    font-size: 0.5em;
  }
`;

export const TextCustomStyle = styled.span`
  ${defaultStyle};
  font-size: ${(props) => props.theme.fontSize[props.size]};
  cursor: ${(props) => props.onClick && "pointer"};

  @media only screen and (max-width: 468px) {
    &.full-width{
      width:100%;
    }
  }
`;
