import React from "react";
import { ButtonStyle } from "./style";

export const Button = (props) => {
  const { children, className, color, size, onClick, outline,margin,aos,
    duration} = props;
  return (
    <ButtonStyle
      data-aos={aos}
      data-aos-duration={duration}
      outline={outline && outline}
      className={className}
      size={size}
      onClick={onClick}
      color={color}
      margin={margin}
    >
      {children}
    </ButtonStyle>
  );
};
