import React,{useEffect}  from "react";
import LandingPage from "./landingPage";
import Intro from "./intro";
import WhyUs from "./whyUs";
import OurTeam from "./ourTeam";
import OurProduct from "./ourProduct";
import OurPartners from "./ourPartners";
import LatestNews from "./latestNews";

const Home = (props) => {
  const{news} = props
  
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
    <React.Fragment>
      <LandingPage />
      <Intro />
      <WhyUs />
      <OurTeam />
      <OurProduct />
      <OurPartners />
      <LatestNews news={news}/>
    </React.Fragment>
  );
};

export default Home;
