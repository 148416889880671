import React from 'react'
import {Flex,Button} from '../index'
import {NewsWrapper,Header,Text} from './style'
import { useHistory } from "react-router-dom";
export const NewsBadge = (props) => {
    const {head, info,pic} = props
    const history = useHistory();

    return(
        <NewsWrapper bgImage={pic[0]}>
                <Header>{head}</Header>
                <Text>{info}</Text>
                <Flex width="100%" margin="1.5em 0" justifyContent="center">
                    <Button onClick={() => history.push({pathname:'/newsdetail',state:{head:head,info:info,pic:pic}})} className="inline" size="sm">Read More</Button>
                </Flex>    
        </NewsWrapper>
  
    )
}