import styled from 'styled-components'

export const Wrapper = styled.div`
    position: relative;
    display:flex;
    justify-content:center;
    align-items:center;
    width:500px;
    height:500px;
    margin: 2em 0;
    @media only screen and (max-width: 767.98px) {
    width:250px;
    height:300px;
    }
    /* @media only screen and (max-width: 340px) {
    width:250px;
    height:300px;
    } */
`

export const Line = styled.div`
    width:1px;
    border-left: 1px solid ${props => props.theme.color.grey};
    height: ${props => props.height};
    transform: ${props => props.rotate};
    position: absolute;
    left: ${props => props.left};
    top:${props => props.top};

    @media only screen and (max-width: 767.98px) {
    &.car-part1{
        transform: rotate(350deg);
        top:15px;
        left:100px;
        height:95px;
    }
    &.car-part2{
        transform: rotate(45deg);
        left:155px;
        top:-45px;
        height:45px;
    }
    &.car-part3{
        transform: rotate(0deg);
        left:65px;
        top:-72px;
        height:65px;
    }
    &.car-part4{
        transform: rotate(20deg);
        left:55px;
        top:70px;
        height: 50px;
    }
    &.moto-part1{
        transform: rotate(45deg);
        height:50px;
        left:125px;
        top:-45px;
    }
    &.moto-part2{
        transform: rotate(20deg);
        height:75px;
        left:60px;
        top:-75px;
    }
    &.moto-part3{
        transform: rotate(340deg);
        height:35px;
        left:130px;
        top:120px;
    }
    &.moto-part4{
        transform: rotate(120deg);
        height:25px;
        left:145px;
        top:95px;
    }
    }

    /* @media only screen and (max-width: 465px) {
    &.car-part1{
        transform: rotate(350deg);
        top:15px;
        left:97px;
        height:75px;
    }
    &.car-part2{
        transform: rotate(15deg);
        left:160px;
        top:-50px;
        height:45px;
    }
    &.car-part3{
        left:55px;
        top:-72px;
    }
    &.car-part4{
        transform: rotate(15deg);
        left:45px;
        top:65px;
        height: 55px;
    }
    &.moto-part1{
        transform: rotate(15deg);
        height:55px;
        left:130px;
        top:-60px;
    }
    &.moto-part2{
        transform: rotate(0deg);
        height:60px;
        left:30px;
        top:-70px;
    }
    &.moto-part3{
        transform: rotate(0deg);
        height:60px;
        left:115px;
        top:120px;
    }
    &.moto-part4{
        transform: rotate(140deg);
        height:50px;
        left:130px;
        top:90px;
    }
    } */
`




export const HiddenDiv = styled.div`
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;  
  position: absolute;
    &.car-part1{
    top: 100px;
    left:-100px; 
    
    }
    &.car-part2{

    top: 400px;
    left:100px; 
    }
    
    &.car-part3{

    top: 350px;
    left:-70px; 
    }

    &.car-part4{
    
    top: -20px;
    left:150px; 
    }

    &.moto-part1{
    top: 400px;
    left:120px; 
    }

    &.moto-part2{

    top:150px;
    left:430px; 
    }

    &.moto-part3{
    top:-50px;
    left:300px; 
    }

    &.moto-part4{
    top: 0px;
    left:70px; 
    }
    @media only screen and (max-width: 767.98px) {
        &.car-part1{
            left:-30px;
            top:-20px;
        }
        &.car-part2{
            left:-10px;
            top:240px;
        }
        &.car-part3{
            top: 240px;
            left:-50px; 
        }
        &.car-part4{
        top: -50px;
        left:100px; 
        }
        &.moto-part1{
        top: 250px;
        left:0px; 
        }
        &.moto-part2{
        top: 250px;
        left:150px; 
        }
        &.moto-part3{
        top: -80px;
        left:50px; 
        }
        &.moto-part4{
        top: -30px;
        left:-30px; 
        }
    }

    /* @media only screen and (max-width: 465px) {
        &.car-part1{
            left:0px;
            top:100px;
        }
        &.car-part2{
            left:50px;
            top:350px;
        }
        &.car-part3{
            top: 350px;
            left:0px; 
        }
        &.car-part4{
        top: 50px;
        left:150px; 
        }
        &.moto-part1{
        top: 360px;
        left:0px; 
        }
        &.moto-part2{
        top: 350px;
        left:220px; 
        }
        &.moto-part3{
        top: 0px;
        left:100px; 
        }
        &.moto-part4{
        top: 50px;
        left:10px; 
        }
    } */
`
export const InteractBtn = styled.div`
    width:25px;
    height:25px;
    position: absolute;
    left: ${props => props.left};
    top:${props => props.top};
    cursor: pointer;
    &.car-part1{
        left:120px;
        top:180px;
        &:hover + ${HiddenDiv}{
                &.car-part1{
                    visibility: visible;
                    opacity: 1;
            } 
        }
    }

    &.car-part2{
        &:hover + ${HiddenDiv}{
                &.car-part2{
                    visibility: visible;
                    opacity: 1;
            } 
        }
    }

    &.car-part3{
        top:250px;
        left:50px;
        &:hover + ${HiddenDiv}{
                &.car-part3{
                    visibility: visible;
                    opacity: 1;
            } 
        }
    }

    &.car-part4{
        top:125px;
        left:250px;
        &:hover + ${HiddenDiv}{
                &.car-part4{
                    visibility: visible;
                    opacity: 1;
            } 
        }
    }
    
    &.moto-part1{
        top:310px;
        left:250px;
        &:hover + ${HiddenDiv}{
                &.moto-part1{
                    visibility: visible;
                    opacity: 1;
            } 
        }
    }

    &.moto-part2{
        top:225px;
        left:390px;
        &:hover + ${HiddenDiv}{
                &.moto-part2{
                    visibility: visible;
                    opacity: 1;
            } 
        }
    }

    &.moto-part3{
        top:150px;
        left:300px;
        &:hover + ${HiddenDiv}{
                &.moto-part3{
                    visibility: visible;
                    opacity: 1;
            } 
        }
    }

    &.moto-part4{
        top:160px;
        left:250px;
        &:hover + ${HiddenDiv}{
                &.moto-part4{
                    visibility: visible;
                    opacity: 1;
            } 
        }
    }

    @media only screen and (max-width: 767.98px) {
        &.car-part1{
            left:60px;
            top:90px;
        }
        &.car-part2{
            left:150px;
            top:170px;
        }
        &.car-part3{
            left:0px;
            top:130px;
        } 
        &.car-part4{
        top:70px;
        left:130px;
        }
        &.moto-part1{
        top:180px;
        left:130px;
        }
        &.moto-part2{
        top:150px;
        left:215px;
        }
        &.moto-part3{
        top:70px;
        left:175px;
        }
        &.moto-part4{
        top:80px;
        left:120px;
        }
    }
    /* @media only screen and (max-width: 465px) {
        &.car-part1{
            left:90px;
            top:190px;
        }
        &.car-part2{
            left:200px;
            top:270px;
        }
        &.car-part3{
            left:50px;
            top:250px;
        } 
        &.car-part4{
        top:170px;
        left:170px;
        }
        &.moto-part1{
        top:270px;
        left:120px;
        }
        &.moto-part2{
        top:230px;
        left:230px;
        }
        &.moto-part3{
        top:180px;
        left:200px;
        }
        &.moto-part4{
        top:190px;
        left:150px;
        }
    } */
    
`