import React from 'react'
import {Section, Flex,Text,Badge,NewsBadge, DivWrapper} from '../../components'
import {Container} from 'reactstrap'
import { useHistory } from "react-router-dom";

const LatestNews = props => {
    const{news} = props
    const history = useHistory();
return (
    <Section bgColor="white" padding="0 0 4em 0">
        <Container>
        <Flex alignItems="center" justifyContent="center" width="100%">
            <Text.H3  aos="fade-up"
            duration="1200"fontFamily="'Lora', serif" margin="1em 0" fontColor="grey"   spacing="3px">   
              LATEST
                <span style={{color: "#E7A53C",fontWeight: "600",margin: "0 0.25em",shadow: "0",}}> 
                NEWS 
                </span>
            </Text.H3> 
            <Text.H6 >
                <DivWrapper aos="fade-up"
            duration="1200"onClick={() => history.push("/news")}>
                <Badge  color="secondary" className="py-1 px-2">
                    View all
                </Badge>
                </DivWrapper>
            </Text.H6>
        </Flex>
        <Flex aos="fade-up"
            duration="1200"className="responsive-news" width="100%" alignItems="center" justifyContent="space-between" wrap="wrap">
            {news && news.slice(0,4).reverse().map((data,index) => (
                 <NewsBadge key={data.no} head={data.header} info={data.info} pic={data.pic}/>
            ))}
        </Flex>
        </Container>
    </Section>
)
}
export default LatestNews