import React from 'react'
import {Section,Flex,DivWrapper,Text} from '../../components'
import Bg from '../../assets/bg-product-header.png'

const Header = () => {
return (
    <Section bgImage={Bg}>
        <Flex width="100%" height="400px" alignItems="center" justifyContent="center">
            <DivWrapper aos="fade-up"
            duration="1200"className="header-border">
            <Text.H3 aos="fade-up"
            duration="1400"
            fontFamily="'Lora', serif"
            fontColor="white" weight={600}  spacing="3px" margin='0'>
                 <span
              style={{
                color: "#E7A53C",
                fontWeight: "600",        
                margin: "0 0.25em",
                shadow: "0",
              }}
            > OUR </span>
                PRODUCTS
            </Text.H3>
            </DivWrapper>
        </Flex>
    </Section>
)
}
export default Header