import React from 'react'
import {Flex,ImgWrapper,Text} from '../index'
import {Wrapper,InteractBtn,HiddenDiv,Line} from './style'
import Car from '../../assets/car.png'
import Moto from '../../assets/moto.png'
import CarPart1 from '../../assets/car-part1.png'
import CarPart2 from '../../assets/car-part2.png'
import CarPart3 from '../../assets/car-part3.png'
import CarPart4 from '../../assets/car-part4.png'
import MotoPart1 from '../../assets/moto-part1.png'
import MotoPart2 from '../../assets/moto-part2.png'
import MotoPart3 from '../../assets/moto-part3.png'
import MotoPart4 from '../../assets/moto-part4.png'
import InteractButton from '../../assets/interactButton.png'

export const InteractiveProduct = () => {
return (
    <Flex width="100%" justifyContent="space-around" wrap="wrap">
        <Flex className="responsive-fullwidth">
             <InteractiveCar/>
        </Flex>
       <Flex className="responsive-fullwidth">
           <InteractiveMotocycle/>
       </Flex>
        
    </Flex>
)
}

const InteractiveCar = () => {
    return(
        <Wrapper>
            <ImgWrapper className="auto" source={Car} width="400px"/>
            <Car1/>
            <Car2/>
            <Car3/>
            <Car4/>
        </Wrapper>
    )
}

const Car1 = () => {
    return(
        <React.Fragment>
            <InteractBtn className="car-part1" >
                <ImgWrapper  source={InteractButton}/>
                </InteractBtn>
                <HiddenDiv className="car-part1">
                    <Flex flexDirection="column" alignItems="center">
                         <Text.H5 weight={700} fontColor="blue">Windshield wiper</Text.H5>
                         <ImgWrapper className="car-part1"source={CarPart1} width="150px"/>
                    </Flex>
                    <Line className="car-part1" top="5px" left="185px" height="100px" rotate="rotate(130deg)"/>
                </HiddenDiv>
        </React.Fragment>
    )
}

const Car2 = () => {
    return (
        <React.Fragment>
        <InteractBtn className="car-part2" left="240px" top="320px">
            <ImgWrapper source={InteractButton}/>
            </InteractBtn>
            <HiddenDiv className="car-part2">
                <Flex flexDirection="column" alignItems="center">
                     <Text.H5 weight={700} fontColor="blue">Hand breaks cable</Text.H5>
                     <ImgWrapper className="car-part2"source={CarPart2} width="450px"/>
                </Flex>
                <Line className="car-part2"top="-65px" left="195px" height="70px" rotate="rotate(130deg)"/>
            </HiddenDiv>
        </React.Fragment>
    )
}



const Car3 = () => {
    return (
       
        <React.Fragment>
        <InteractBtn className="car-part3" >
            <ImgWrapper source={InteractButton}/>
            </InteractBtn>
            <HiddenDiv className="car-part3">
                <Flex flexDirection="column" alignItems="center">
                     <Text.H5 weight={700} fontColor="blue">Bracket compressor</Text.H5>
                     <ImgWrapper className="car-part3"source={CarPart3} width="250px"/>
                </Flex>
                <Line className="car-part3"top="-72px" left="115px" height="75px" rotate="rotate(20deg)"/>
            </HiddenDiv>
        </React.Fragment>
    )
}

const Car4 = () => {
    return (
       
        <React.Fragment>
        <InteractBtn className="car-part4" >
            <ImgWrapper source={InteractButton}/>
            </InteractBtn>
            <HiddenDiv className="car-part4">
                <Flex flexDirection="column" alignItems="center">
                     <ImgWrapper className="car-part4"source={CarPart4} width="250px"/>
                      <Text.H5 weight={700} fontColor="blue">Safety belt</Text.H5>
                </Flex>
                <Line className="car-part4"top="100px" left="105px" height="50px" rotate="rotate(345deg)"/>
            </HiddenDiv>
        </React.Fragment>
    )
}

const InteractiveMotocycle = () => {
    return(
        <Wrapper>
            <ImgWrapper className="auto" source={Moto} width="400px"/>
            <Moto1/>
            <Moto2/>
            <Moto3/>
            <Moto4/>
        </Wrapper>
    )
}


const Moto1 = () => {
    return(
        <React.Fragment>
        <InteractBtn className="moto-part1" >
            <ImgWrapper source={InteractButton}/>
            </InteractBtn>
            <HiddenDiv className="moto-part1">
                <Flex flexDirection="column" alignItems="center">
                     <Text.H5 weight={700} fontColor="blue">Hand breaks cable</Text.H5>
                     <ImgWrapper className="moto-part1"source={MotoPart1} width="400px"/> 
                </Flex>
                <Line className="moto-part1"top="-60px" left="155px" height="60px" rotate="rotate(345deg)"/>
            </HiddenDiv>
        </React.Fragment>
    )
}

const Moto2 = () => {
    return(
        <React.Fragment>
        <InteractBtn className="moto-part2" >
            <ImgWrapper source={InteractButton}/>
            </InteractBtn>
            <HiddenDiv className="moto-part2">
                <Flex flexDirection="column" alignItems="center">
                     <Text.H5 weight={700} fontColor="blue">Shock absorber</Text.H5>
                     <ImgWrapper className="moto-part2"source={MotoPart2} width="150px"/> 
                </Flex>
                <Line className="moto-part2"top="20px" left="0px" height="65px" rotate="rotate(35deg)"/>
            </HiddenDiv>
        </React.Fragment>
    )
}

const Moto3 = () => {
    return(
        <React.Fragment>
        <InteractBtn className="moto-part3" >
            <ImgWrapper source={InteractButton}/>
            </InteractBtn>
            <HiddenDiv className="moto-part3">
                <Flex flexDirection="column" alignItems="center">
                     <ImgWrapper source={MotoPart3} width="250px"/> 
                      <Text.H5 weight={700} fontColor="blue">Ignition Set and Cover</Text.H5>
                </Flex>
                <Line className="moto-part3"top="135px" left="30px" height="70px" rotate="rotate(20deg)"/>
            </HiddenDiv>
        </React.Fragment>
    )
}

const Moto4 = () => {
    return(
        <React.Fragment>
        <InteractBtn className="moto-part4" >
            <ImgWrapper source={InteractButton}/>
            </InteractBtn>
            <HiddenDiv className="moto-part4">
                <Flex flexDirection="column" alignItems="center">
                     <ImgWrapper className="moto-part4"source={MotoPart4} width="230px"/> 
                      <Text.H5 weight={700} fontColor="blue">Fuel tank</Text.H5>
                </Flex>
                <Line className="moto-part4"top="100px" left="165px" height="70px" rotate="rotate(320deg)"/>
            </HiddenDiv>
        </React.Fragment>
    )
}

export default InteractiveProduct