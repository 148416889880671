import styled from 'styled-components'

export const A = styled.a`
    text-decoration: none;
    color: white;
    font-size: ${(props) => props.theme.fontSize.default};
    transition-duration: 0.3s;
    :hover{
        text-decoration: none;
        color: ${props => props.theme.color.secondary};
    }
`

export const FooterWrapper = styled.div`
    &.responsive{
        display:none;
    }
    @media only screen and (max-width: 768px) {
    &.responsive{
        display:block;
    }

    &.fullFooter{
        display:none;
    }

    }



`