import { Button } from "reactstrap";
import styled from "styled-components";

export const ButtonStyle = styled(Button)`
margin: ${props => props.margin};
    &.outline{
        border: 1px solid ${props => props.theme.color.secondary};
        background-color: rgb(0,0,0,0);
        color: ${props => props.theme.color.secondary};
        
    }
    &.inline{
        border: 1px solid ${props => props.theme.color.secondary};
        background-color: ${props => props.theme.color.secondary};
        color: ${props => props.theme.color.white};
    }
    
    &:hover{
        &.outline{
            border: 1px solid ${props => props.theme.color.secondary};
        background-color:${props => props.theme.color.secondary};
        color: ${props => props.theme.color.white};
    }
    &.inline{
        border: 1px solid ${props => props.theme.color.secondary};
        background-color: rgb(0,0,0,0);
        color: ${props => props.theme.color.secondary};
    }
       
    }
`;
