import styled from 'styled-components'

export const ImgStyle = styled.img`
width: ${props => props.width};
height: ${props => props.height};
filter: ${props => props.filter};
&.partner{
    filter: opacity(20%);
    transition-duration: 0.3s;
    width: 200px;
    margin: 1em;
}

:hover{
    &.partner{
        filter: none;
    }
}


    @media only screen and (max-width: 767.98px) {
    &.partner{
        width: 100px;
        height:100%;
    }
    &.auto{
        width:250px;
    }
    &.car-part1{
        width:100px
    }
    &.car-part2{
        width:300px
    }
    &.car-part3{
        width:150px
    }
    &.car-part4{
        width:180px
    }
    &.moto-part1{
        width: 250px;
    }
    &.moto-part2{
        width: 100px;
    }
}
`