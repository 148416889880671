import React,{useEffect}  from "react";
import Automotive from './automotive'
import Header from './header'
import ElectronicAndHome from './electronicAndHome'
import Medical from './medical'
import AllPartners from '../contact/allPartners'
import LatestNews from '../home/latestNews'

const OurProduct = (props) => {
  const {news} = props
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
   <React.Fragment>
     <Header/>
     <Automotive/>
     <ElectronicAndHome/>
     <Medical/>
     <AllPartners/>
     <LatestNews news={news}/>
   </React.Fragment>
  );
};

export default OurProduct;
