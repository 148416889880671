import React from 'react'
import {Section,Flex,Text,Button} from '../../components'
import Bg from '../../assets/bg-our-productt.png'
import { useHistory } from "react-router-dom";

const OurProduct = () => {
  const history = useHistory();
return (
    <Section bgImage={Bg}>
        <Flex  height="450px" flexDirection="column" justifyContent="center" alignItems="center">
        <Text.H3 aos="fade-left"
            duration="1200"
            fontFamily="'Lora', serif"
            margin="1em 0" fontColor="white" weight={600} spacing="3px">
            <span
              style={{
                color: "#E7A53C",
                fontWeight:'400',
                margin: "0 0.25em",
                shadow: "0",
              }}
            > OUR 
            </span>
              PRODUCT
            </Text.H3>
            <Button aos="fade-right"
            duration="1200"onClick={() => history.push("/product")} margin="2em 0" className="inline px-4">View Product</Button>
        </Flex>
    </Section>
)
}
export default OurProduct