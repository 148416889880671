import React from 'react'
import {Section,Flex,Text,DivWrapper} from '../../components'
import {Container} from 'reactstrap'

export const OurMachine = () => {
return (
    <Section bgcolor="darkGrey" padding="2em 0">
        <Container>
            <Flex alignItems="center" flexDirection="column">
                <Text.H3 aos="fade-up"
            duration="1200"
                fontFamily="'Lora', serif"
                margin="1em 0" fontColor="white"  spacing="1px">
                    OUR  <span
                style={{
                    color: "#E7A53C",
                    margin: "0 0.25em",
                    shadow: "0",
                }}
                > MACHINES </span>
                </Text.H3>
                <Flex className="responsive-fullwidth" wrap="wrap" width="100%" justifyContent="space-between">
                    <Flex flexDirection="column">
                        <Exp1/>
                        <Exp3/>
                    </Flex>
                    <Flex flexDirection="column">
                        <Exp2/>
                        <Exp4/>
                    </Flex>
                </Flex>
            </Flex>
        </Container>
    </Section>
)
}

const Exp1 = () =>{
    return(
        // <Flex margin="2em 0 1em 0">
        //     <Numb numb="1"/>
        <Flex aos="fade-right"
        duration="1200"className="responsive-fullwidth"  flexDirection="column">
            <Text.H4 fontFamily="'Lora', serif"  fontColor="secondary"margin="2em 0 1em 0">Mold Making Machines</Text.H4>
            <Flex >
                <Flex flexDirection="column" margin="0 1em 0 0">
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px" margin="0.5em 0" fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>Machining Center</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>Wire EDM</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>NC EDM</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>Die Spot</Text.Custom>
                </Flex>
                <Flex flexDirection="column" margin="0 1em 0 0">
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>Grinding Machine</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>NC Milling</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>Milling</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>Radial Arm Drill</Text.Custom>
                </Flex>
                <Flex flexDirection="column" margin="0 1em 0 0">
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>EDM Small Drill</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>Graphite Machining Center</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>Drilling Tapping Machine</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>Lathe</Text.Custom>
                </Flex>
            </Flex>
        </Flex>
        // </Flex>
    )
}

const Exp2 = () =>{
    return(
      //  <Flex margin="2em 0 1em 0">
      //  <Numb numb="1"/>
        <Flex aos="fade-left"
        duration="1200"className="responsive-fullwidth" flexDirection="column" >
            <Text.H4 fontFamily="'Lora', serif" fontColor="secondary"margin="2em 0 1em 0">Measurement Tools</Text.H4>
            <Flex >
                <Flex flexDirection="column" margin="0 2em 0 0">
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px" margin="0.5em 0" fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>Coordinate Measuring Machine (CMM)</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>High gauge digital</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>Digital micro scope</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>Dial indicators</Text.Custom>
                </Flex>
                <Flex flexDirection="column" margin="0 2em 0 0">
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>Micrometers, Clipers</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>Custom engineered gauges</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>Gage blocks</Text.Custom>
                    
                </Flex>
                </Flex>
            </Flex>
       //  </Flex>
    )
}

const Exp3 = () =>{
    return(
        <Flex aos="fade-right"
        duration="1200"className="responsive-fullwidth" flexDirection="column" >
            <Text.H4 fontFamily="'Lora', serif" fontColor="secondary"margin="2em 0 1em 0">Die Casting Machines</Text.H4>
            <Flex >
                <Flex flexDirection="column" >
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" fontColor="secondary" spacing="1px" margin="0.5em 0" >Aluminum Die Casting Machine List</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>Die Casting Machine 500 Ton</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>Die Casting Machine 350 Ton</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>Die Casting Machine 150 Ton</Text.Custom>
                </Flex>
                <Line/>
                <Flex flexDirection="column" >
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" fontColor="secondary"spacing="1px"margin="0.5em 0">Zinc Die Casting Machine List</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>90 Ton</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>50 Ton</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>25 Ton</Text.Custom>
                </Flex>
              
            </Flex>
        </Flex>
    )
}

const Exp4 = () =>{
    return(
        <Flex aos="fade-left"
        duration="1200"className="responsive-fullwidth" flexDirection="column" >
            <Text.H4 fontFamily="'Lora', serif" fontColor="secondary"margin="2em 0 1em 0">Metal Stamping and CNC Machines</Text.H4>
            <Flex >
                <Flex flexDirection="column" >
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" fontColor="secondary" spacing="1px" margin="0.5em 0" >Metal Stamping Machine List</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>200 Ton</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>110 Ton</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>80 Ton</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>60 Ton</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>45 Ton</Text.Custom>
                </Flex>
                <Line/>
                <Flex flexDirection="column" >
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" fontColor="secondary"spacing="1px"margin="0.5em 0">CNC Machine List</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>CNC Lathe</Text.Custom>
                    <Text.Custom size="xSmall" fontFamily="'Lora', serif" spacing="1px"margin="0.5em 0"fontColor="white"><span style={{color:'#E7A53C',marginRight:'1em' }}>●</span>CNC Drilling Tapping</Text.Custom>
                </Flex>
              
            </Flex>
        </Flex>
    )
}

const Line = () => {
    return(
        <DivWrapper className="line-vertical"/>
    )
}

// const Numb = props =>{
//     const {numb} = props
//     return(
//         <DivWrapper className="number-circle">
//             <Text.H5 weight={700} fontColor="white">{numb}</Text.H5>
//         </DivWrapper>
//     )
// }



export default OurMachine