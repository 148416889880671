import React from "react";
import { Section, Flex, Text, ImgWrapper } from "../../components";
import { Container } from "reactstrap";
import P1 from "../../assets/partner1.png";
import P2 from "../../assets/partner2.png";
import P3 from "../../assets/partner3.png";
import P4 from "../../assets/partner4.png";
import P5 from "../../assets/partner5.png";
import P6 from "../../assets/partner6.png";
import P7 from "../../assets/partner7.png";
import P8 from "../../assets/partner8.png";
import P9 from "../../assets/partner9.png";
import P10 from "../../assets/partner10.png";
import P11 from "../../assets/partner11.png";
import P12 from "../../assets/partner12.png";
import P13 from "../../assets/partner13.png";
import P14 from "../../assets/partner14.png";
import P15 from "../../assets/partner15.png";
import P17 from "../../assets/partner17.png";
import P18 from "../../assets/partner18.png";
import P19 from "../../assets/partner19.png";
import P20 from "../../assets/partner20.png";
import P21 from "../../assets/partner21.png";
import P22 from "../../assets/partner22.png";

const AllPartners = () => {
  return (
    <Section bgColor="white" padding="2em 0">
      <Container>
        <Flex alignItems="center" justifyContent="center" width="100%">
          <Text.H3
          aos="fade-up"
          duration="1400"
            fontFamily="'Lora', serif"
            margin="1em 0"
            fontColor="grey"
            spacing="3px"
          >
            OUR
            <span
              style={{
                color: "#E7A53C",
                fontWeight: "600",
                margin: "0 0.25em",
                shadow: "0",
              }}
            >
              VALUABLE
            </span>
            <span
              style={{
                color: "#3A5277",
                fontWeight: "600",
                margin: "0 0.25em",
                shadow: "0",
              }}
            >
              PARTNERS
            </span>
          </Text.H3>
        </Flex>
        <Flex
          margin="2em 0 1em 0"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
          wrap="wrap"
        >
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P1} className="partner" />
          </Flex>
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P2} className="partner" />
          </Flex>
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P3} className="partner" />
          </Flex>
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P4} className="partner" />
          </Flex>
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P5} className="partner" />
          </Flex>
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P6} className="partner" />
          </Flex>
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P7} className="partner" />
          </Flex>
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P8} className="partner" />
          </Flex>
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P9} className="partner" />
          </Flex>
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P10} className="partner" />
          </Flex>
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P11} className="partner" />
          </Flex>
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper
          
              source={P12}
              className="partner"
            />
          </Flex>
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P13} className="partner" />
          </Flex>
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P14} className="partner" />
          </Flex>
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P15} className="partner" />
          </Flex>
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P17} className="partner" />
          </Flex>
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P18} className="partner" />
          </Flex>
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P19} className="partner"
            />
          </Flex>
          <Flex aos="fade-up"
        duration="1200" className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P20} className="partner" />
          </Flex>
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P21} className="partner" />
          </Flex>
          <Flex aos="fade-up"
        duration="1200"className="responsive-partner"justifyContent="center" width="250px">
            <ImgWrapper source={P22} className="partner" />
          </Flex>
        </Flex>
      </Container>
    </Section>
  );
};
export default AllPartners;
