import React from 'react'
import {Section,Flex,DivWrapper,Text} from '../../components'
import {Container} from 'reactstrap'
import Bg from '../../assets/bg-exp-header.png'

const Header = () => {
return (
    <Section bgImage={Bg}>
        <Container>
        <Flex flexDirection="column" width="100%" height="400px" alignItems="center" justifyContent="center">
            <DivWrapper aos="fade-up"
            duration="1200"className="header-border">
            <Text.H3 aos="fade-up"
            duration="1400"
            fontFamily="'Lora', serif"
            fontColor="white" textAlign="Center" spacing="1px" margin='0'>
                OVER 30 YEARS
                 <span
              style={{
                color: "#E7A53C",
                margin: "0 0.25em",
                shadow: "0",
              }}
            > EXPERIENCE </span>
         
            </Text.H3>
            </DivWrapper>
            <Text.H5 aos="fade-up"
            duration="1600" margin="2em 0 0 0" lineHeight="1.5em" fontColor="white" textAlign="center">As a mold maker, we are specialized in many fields of mold such as Aluminum Die Casting Mold, Zinc Die Casting Mold, Plastic Injection Mold, Metal Stamping mold by using Unigraphic NX Software to design and we also use AnyCast software for casting analysis that minimizes the defects by predicting various defects that can occur during casting based on filling and solidification of the casting process.</Text.H5>
        </Flex>
        </Container>
    </Section>
)
}
export default Header