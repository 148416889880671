import styled from "styled-components";

export const FlexStyle = styled.div`
  display: flex;
  flex-wrap:${props => props.wrap};
  flex-direction: ${(props) => props.flexDirection};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  width: ${(props) => props.width};
  max-width: ${props => props.maxWidth};
  min-width: ${props => props.minWidth};
  height: ${(props) => props.height};
  background-image: url(${props => props.bgImage});
  background: ${props => props.theme.color[props.bgColor]};
  cursor: ${props => props.cursor};
  opacity: ${props => props.opacity};

  @media only screen and (max-width: 1200px) {
  &.responsive-fullwidth{
    width: 100%;
    justify-content:center;
    align-items:center;
  }

  &.responsive-partner{
    width: 200px;
  }
  }

  @media only screen and (max-width: 768px) {
    &.full-navbar{
      display:none;
    }
    &.responsive-fullwidth-column{
      flex-direction:column;
      width: 100%;
      justify-content:center;
      align-items:center;
    }
   }

   @media only screen and (max-width: 468px) {
      &.responsive-news{
        width:100%;
        justify-content:center;
        flex-direction:column;
      }
      &.allies-card{
        width:100%;
      }
      &.responsive-partner{
       width: 100px;
      }
   }
  
`;
