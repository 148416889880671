import React from "react";
import { FlexStyle } from "./style";

export const Flex = (props) => {
  const {
    children,
    className,
    flexDirection,
    justifyContent,
    alignItems,
    margin,
    padding,
    width,
    maxWidth,
    minWidth,
    height,
    bgImage,
    bgColor,
    wrap,
    onClick,
    cursor,
    opacity,
    aos,
    duration
  } = props;
  return (
    <FlexStyle
    data-aos={aos}
    data-aos-duration={duration}
      className={className}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      alignItems={alignItems}
      margin={margin}
      padding={padding}
      width={width}
      maxWidth={maxWidth}
      minWidth={minWidth}
      height={height}
      bgImage={bgImage}
      bgColor={bgColor}
      wrap={wrap}
      onClick={onClick}
      cursor={cursor}
      opacity={opacity}
    >
      {children}
    </FlexStyle>
  );
};
