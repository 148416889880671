import styled from "styled-components";

export const SectionStyle = styled.div`
  background: ${(props) => props.theme.color[props.bgcolor]};
  background-image: url(${props => props.bgImage});
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${props => props.padding};
  overflow:hidden;
`;
