import React from 'react'
import {Section,Flex,DivWrapper,Text} from '../index'
import{A,FooterWrapper} from './style'
import {Container} from 'reactstrap'
import Logo from '../../assets/logo.png'
export const Footer = () =>{
    return(
        <Section bgcolor="primary">
            <Container>
                <FooterWrapper className="fullFooter">
            <Flex width="100%"  padding="3em 0 2em 0"justifyContent="space-between">
                <Flex flexDirection="column" >
                    <Flex >
                    <DivWrapper width="50px" margin="-1.3em 0.5em 2em -1em">
                        <img src={Logo} alt="logo" style={{width:'50px'}}/>
                    </DivWrapper>
                        <Text.H5 fontColor="white" weight={500}>MICRO<span style={{fontWeight:'700'}}>PRECISION</span> CO., LTD.</Text.H5>
                    </Flex>
                    <Text.H5 fontColor="white" >669-669/3 Moo.15 Theparak Rd. Km.21, Bangsaothong, Samutprakarn 10570</Text.H5>
                </Flex>
                <Flex flexDirection="column" justifyContent="space-between" alignItems="flex-end">
                <A href="tel:+660-2706-5100">Tel: +66 (0) 2706 5100 (8 Lines)</A>
                <A href="tel:+660-2706-5725">Fax: +66 (0) 2706 5725</A>
                <A href="mailto:planning@mcp.co.th">Email: websitecontact@mcp.co.th</A>
                </Flex>
            </Flex>
            </FooterWrapper>
            <FooterWrapper className="responsive">
            <Flex width="100%"  padding="3em 0 2em 0" flexDirection="column">
                    <Flex >
                    <DivWrapper width="50px" margin="-1.3em 0.5em 0 -1em">
                        <img src={Logo} alt="logo" style={{width:'50px'}}/>
                    </DivWrapper>
                        <Text.Custom size="1.5em" fontColor="white" weight={500}>MICRO<span style={{fontWeight:'700'}}>PRECISION</span> CO., LTD.</Text.Custom>
                    </Flex>
                    <A href="tel:+660-2706-5100">Tel: +66 (0) 2706 5100 (8 Lines)</A>
                    <A href="tel:+660-2706-5725">Fax: +66 (0) 2706 5725</A>
                    <A href="mailto:planning@mcp.co.th">Email: planning@mcp.co.th</A>
                    <Text.H5 margin="1em 0 0 0" fontColor="white" weight={700}>669-669/3 Moo.15 Theparak Rd. Km.21, Bangsaothong, Samutprakarn 10570</Text.H5>
            </Flex>
            </FooterWrapper>
            </Container>
        </Section>
    )
}