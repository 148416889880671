import React from "react";
import { DivStyle } from "./style";

export const DivWrapper = (props) => {
  const { children, className, width, height, padding, margin,overflow,onClick, aos,
  duration } = props;
  return (
    <DivStyle
      data-aos={aos}
      data-aos-duration={duration}
      className={className}
      width={width}
      height={height}
      padding={padding}
      margin={margin}
      overflow={overflow}
      onClick={onClick}
    >
      {children}
    </DivStyle>
  );
};
