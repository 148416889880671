import React,{useState,useEffect} from "react";
import { Container } from "reactstrap";
import { Section, Flex, Text, DivWrapper } from "../index";
import {
  Wrapper,
  SlideDrawerStyled,
  DivStyle,
  SpanStyle
} from './style';
import Logo from "../../assets/logo.png";
import { useHistory } from "react-router-dom";

export const NavBar = () => {
  const history = useHistory();
  const [isCollapsed,setCollapsed] = useState(true)

  useEffect(() => {
    if (!isCollapsed) {
      document.body.classList.add('lock-screen')
    } else {
      document.body.classList.remove('lock-screen')
    }
  }, [isCollapsed])

const onNavigate = (path) => {
  history.push(`${path}`)
  setCollapsed(!isCollapsed)
}
  return (
    <Section>
      <Container>
        <Flex  bgcolor="white" justifyContent="space-between">
          <Flex
            alignItems="center"
            onClick={() => history.push("/")}
            cursor="pointer"
            height="69px"
            justifyContent="center"
          >
            <DivWrapper  aos="fade-down"
            duration="1000" width="50px" margin="0 0.5em 0 0">
              <img src={Logo} alt="logo" style={{width:'50px'}} />
            </DivWrapper>
            <Text.Custom  aos="fade-down"
            duration="1200"size="1.5em"weight={500}>MICRO</Text.Custom>
            <Text.Custom  aos="fade-down"
            duration="1200"size="1.5em" weight={700}>PRECISION</Text.Custom>
          </Flex>
          <Flex className="full-navbar"alignItems="center" height="69px">
            <DivWrapper  aos="fade-down"
            duration="1000">
               <Text.H5 className="nav" onClick={() => history.push("/product")}>
              PRODUCT
            </Text.H5>
            </DivWrapper>
            <DivWrapper  aos="fade-down"
            duration="1200"> <Text.H5
              className="nav"
              onClick={() => history.push("/experience")}
            >
              OUR EXPERIENCE
            </Text.H5></DivWrapper>
           
           
            <DivWrapper  aos="fade-down"
            duration="1400">
            <Text.H5 className="nav" onClick={() => history.push("/news")}>NEWS</Text.H5>
            </DivWrapper>
            <DivWrapper  aos="fade-down"
            duration="1600">
 <Text.H5 className="nav" onClick={() => history.push("/contact")}>CONTACT</Text.H5>
            </DivWrapper>
           
          </Flex>
        <DivWrapper className="responsive-navbar">
          <Hamburger isCollapsed={isCollapsed} setCollapsed={setCollapsed}/>
          <Wrapper onClick={() => setCollapsed(!isCollapsed)} collapsed={isCollapsed} />
      <SlideDrawerStyled collapsed={isCollapsed}>
      <Text.H3 className="responsive-nav" margin="1em 0 0 1em" fontColor="white" onClick={() => onNavigate("/")}>HOME</Text.H3>
        <Text.H3 className="responsive-nav" margin="1em 0 0 1em" fontColor="white" onClick={() => onNavigate("/product")}>PRODUCT</Text.H3>
        <Text.H3 className="responsive-nav" margin="1em 0 0 1em" fontColor="white" onClick={() => onNavigate("/experience")}>OUR EXPERIENCE</Text.H3>
        <Text.H3 className="responsive-nav" margin="1em 0 0 1em" fontColor="white" onClick={() => onNavigate("/news")}>NEWS</Text.H3>
        <Text.H3 className="responsive-nav" margin="1em 0 0 1em" fontColor="white" onClick={() => onNavigate("/contact")}>CONTACT</Text.H3>
      </SlideDrawerStyled>
        </DivWrapper>
      </Flex>
      </Container>
    </Section>
  );
};

const Hamburger = props => {
  const { setCollapsed, isCollapsed, isHome } = props
  const bgColor = isHome ? '#ffff' : '#0E55E2'
  const collapsedColor = isHome ? '#00D2FF' : '#ffff'
  return (
    <DivStyle onClick={() => setCollapsed(!isCollapsed)}>
      <SpanStyle
        className="one"
        bgColor={bgColor}
        hoverColor={collapsedColor}
        collapsed={isCollapsed}
      ></SpanStyle>
      <SpanStyle
        bgColor={bgColor}
        hoverColor={collapsedColor}
        collapsed={isCollapsed}
        className="two"
      ></SpanStyle>
      <SpanStyle
        className="three"
        bgColor={bgColor}
        hoverColor={collapsedColor}
        collapsed={isCollapsed}
      ></SpanStyle>
    </DivStyle>
  )
}

