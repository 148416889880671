import React from "react";
import { SectionStyle } from "./style";
export const Section = (props) => {
  const { children,id, className, width, height, bgcolor,bgImage,padding } = props;
  return (
    <SectionStyle
      className={className}
      id={id}
      width={width}
      height={height}
      bgcolor={bgcolor}
      bgImage={bgImage}
      padding={padding}
    >
      {children}
    </SectionStyle>
  );
};
