import React,{useEffect}  from "react";
import Header from "./header";
import MapComponents from "./map";
const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
    <React.Fragment>
      <Header />
      <MapComponents />
    </React.Fragment>
  );
};
export default Contact;
