import React from 'react'
import {Section,Flex,Text,DivWrapper} from '../../components'
import {Container} from 'reactstrap'
import Bg from '../../assets/bg-contact-header.png'
import Bg2 from '../../assets/bg-contact-header-2.png'
import Logo from '../../assets/logo.png'
const Header = () => {
return (
    <Section bgImage={Bg}>
        <Container>
            <Flex height="60vh" justifyContent="flex-end">
                <Flex aos="fade-up" duration="1200"bgImage={Bg2} width="555px" height="100%" flexDirection="column" alignItems="center" justifyContent="center"> 
                    <Container>
                        <Flex flexDirection="column" alignItems="center">
                             <Flex alignItems="baseline" justifyContent="center">
                                <DivWrapper width="50px" margin="0 0.5em 0 0">
                                    <img src={Logo} alt="logo" style={{width:"50px"}}/>
                                </DivWrapper>
                                <DivWrapper className="withborder-bottom">
                                    <Text.H4  weight={400} spacing="2px" fontColor="white" padding="0 0 5px 0"  >MICRO<span style={{letterSpacing:'1px',fontWeight:'600'}}>PRECISION</span></Text.H4>
                                </DivWrapper>   
                            </Flex>
                            <DivWrapper margin="0 0 0 5.5em">
                                <Text.H5 fontColor="white" lineHeight="1.5em" >Mold making, Die casting, Tooling,</Text.H5>
                                <Text.H5 fontColor="white" lineHeight="1.5em" >Metal finishing and CNC machining</Text.H5>
                                <Text.H6 fontColor="white" weight={600} lineHeight="1.5em" >Tel: +66 (0) 27065100 (8 Lines)</Text.H6>
                                <Text.H6 fontColor="white" weight={600} lineHeight="1.5em" >Fax: +66 (0) 2706 5725</Text.H6>
                                <Text.H6 fontColor="white" weight={600} lineHeight="1.5em" >Email: planning@mcp.co.th</Text.H6>
                                <Text.H6 fontColor="white" weight={600} lineHeight="1.5em" >www.mcp.co.th</Text.H6>
                            </DivWrapper>
                        </Flex>
                    </Container>
                </Flex>
            </Flex>
        </Container>
    </Section>
)
}
export default Header